@import 'common';

h1{background: $color-primary; color: white; text-transform: uppercase; font-weight: 900; }
h4{background: $color-primary; color: white; text-transform: uppercase; font-weight: 200; font-size: 1em; padding: 4px 0px 0px 12px;}
.fa-star{color: #ebff00}
sup{color: red}
body{font-family: 'Roboto'}
label{font-weight: 900; letter-spacing: .1em;text-transform: uppercase;font-size: .8em; color:#0275d8}

.modal-dialog{margin-top: 0px;}
.navbar-app{ background: #fcfcfe !important; box-shadow:0px 0px 16px 6px black !important;}
.navbar li a{font-weight: 900;}
.navbar-brand{margin-top: -10px;}
.navbar-toggler{border: none;}
.dropdown-menu{border-radius: 0px ; z-index: 99}
.dropdown-item > .nav-link.active{border-top: none;border-left: thick solid #0275d8 !important; padding-left: 20px; margin-left: -24px;}
.nav-link.active{border-top: thick solid #0275d8; margin-top: -12px;}
.nav-item{text-transform: uppercase;}
.container-locale{font-size: .8em; margin-top: -10px; color: #66CDAA; text-align: right}
.container-office{padding-top: 112px;}
.container-app{padding-top: 64px;}
.link-locale{text-align: right; }
.link-locale:before{content: "/" }
.tag{border-radius: 0px}

//page

	.slide{ width: 100%; height: 520px;
		.carousel-indicators li{display: none;}
		.carousel-indicators  li.active{display: none;}
	}
	.page-header{position: relative; z-index: 99; background-color: $color-primary; height: 80px;}
	.page-body{margin-top: -10%;background: white; opacity: .92; padding: 8px;}

	.page-article{background: white; opacity: .92; padding: 8px; padding-right: 24px;  font-size: 12pt !important;

	}
	.page-article h2{background: orange !important; color: white !important; padding: 4px !important; font-size: 1.1em !important; text-transform: uppercase;}
	.page-duration{font-size: 1.1em !important; margin-bottom: 12px;}
//page
.center > .card{background: white !important}
//home
	.carousel-home{height: auto; overflow: hidden;
		  width: 100%;height: 520px;

	}
	.carousel-item img{width: 100% !important; overflow: visible; }
	.carousel-indicators li{margin-left: 30px; float: right;}
	.carousel-indicators  li.active{margin-left: 30px;}
	.carousel-caption{
		height: 140px; width: 50%; bottom: 5%; left: 1%; z-index: 99;
		 background: $color-primary;
		h1{font-weight: 900; text-align: left; padding-left: 10px; }
		text-shadow: none;
	 .price{ position: absolute; bottom: 10px; right: 5px}
	.duration{position: absolute; bottom: -10px; right: 5px}
	.transport{position: absolute; top: -10px; right: -10px; text-shadow: 2px 2px rgba(0, 0, 0, .2);}
}
	.carousel-inner{   height: 100%;}
	.carousel-inner a{ height: 100%; width: 100%}

	.carousel-item {display: block; position: absolute; width: 100%; height: 100%; background-size: cover; background-position: 50% 50%; }
//home>

.places-limited{ color: #e40000}

.form-sm > .form-group{}
.form-sm > .form-control{}
.form-sm label{font-size: 0.8em}
.form-control{border-radius: 0px;}
.btn{border-radius: 0;}
.btn-fa{border-radius: 16px; width: 32px; height: 32px; padding: 5px;}
.btn-circle-sm{width: 25px; height: 25px; border-radius: 12px; padding: 0px; margin: 5px;}
.mce-tinymce.mce-container.mce-panel{border: thin solid #ddd;}

.card-offer{

	height: auto; background: $color-primary; max-width: 380px;
	min-height: 120px !important;
	h4{font-weight: 900; height: 50px; width: 90%;}
	.fa{color: white;  position: absolute; bottom: 5px; left: 5px; text-shadow: 2px 2px rgba(0, 0, 0, .2);}

		box-shadow: none;
     transition: box-shadow 0.4s;
}
.price{
	position: relative;;
	font-size: 1.8em !important; color: $color-primary; float:right;
	border: thin solid $color-primary;
	background: white;  text-align: right; font-size: 1em; border-radius: 42px  42px 0px 42px ; padding: 5px 10px;

	font-weight: 900}
.price:before{content: "от: "; font-weight: 100; font-size: .7em; position: absolute; left: -28px; color: white; top: 5px;}
.price:after{display: block; content: "лв"; font-weight: 100; font-size: .6em; position: absolute; right: 6px; bottom: -3px;}
.tprice-total:after{right: 42px !important;}
.bgn{
	position: relative;;
	font-size: 1.1em !important; color: #555; float:right;

	font-weight: 900}
.bgn-strike{text-decoration: line-through; font-weight: 100;}
.bgn:before{content: "от: "; font-weight: 100; font-size: .7em; position: absolute; left: -28px; color: white; top: 5px;}
.bgn:after{display: block; content: "лв"; font-weight: 100; font-size: .6em; position: absolute; right: 0px; bottom: -5px;}

.bgn-disc{
	position: relative;;
	font-size: 1.1em !important; color: red; float:right;

	font-weight: 900}
.bgn-disc:before{content: "от: "; font-weight: 100; font-size: .7em; position: absolute; left: -28px; color: white; top: 5px;}
.bgn-disc:after{display: block; content: "лв"; font-weight: 100; font-size: .6em; position: absolute; right: 0px; bottom: -5px;}

.card-offer:hover{box-shadow: 0px 0px 5px black}

.duration{float: right; margin-top: 5px}
	.days{background: #ebff00; color: black;padding: 5px}

	.nights{color: #ebff00; background: black;padding: 5px}
.fa-btn {
    margin-right: 6px;
}
tr.active{background-color: $color-primary !important; }
.table-dates{

	tr.active:after{float: right;
		content: ""; margin-right: -24px; margin-top: 20px;
		border: 12px $color-primary solid; height: 0px; width: 0px;
		border-right: 12px transparent solid;
		border-top: 12px transparent solid;
		border-bottom: 12px transparent solid;
	 }
 }
tr.active>td{border: none !important;}
.table td{padding-bottom: 0px ; }
.table th{padding-bottom: 0px ; border-bottom: none;}

.btn-done{ height: 30px; width: 30px; font-weight: 900; border-radius: 35px 35px 35px 35px ; padding:5px 0px 0px 0px; .fa{font-size: 1em; color: white;} }
.btn-book{  font-weight: 900; font-size: 1.5em !important; border: thin solid $color-primary ; overflow: hidden;
	background: transparent; color: $color-primary; text-transform: uppercase; padding: 6px 0px 6px 15px;
	.fa{font-size: 1.4em; padding-bottom: 4px;}
	.bgn{font-weight: 100; text-transform: none;}
	.price-grand-total{ font-weight: 900; font-size: 1.1em !important;  color: white; background: $color-primary; padding: 13px; margin-right: -1px;}
	.price-grand-total:after{ content: " лв"; font-weight: 100; font-size: 0.8em !important}


}


.btn-skeleton{color: #0275d8; background: none; border: none; padding-top: auto;}
.btn-skeleton:hover{color: #025aa5; background: none;}
.help-block {position: absolute; font-size:  0.8em !important; padding-bottom: 0px; color: #d9534f;}
.with-errors {color: #d9534f; }
//prices
.tprice{ text-align: right; width: 10%;;

}
.tdate{  width: 128px !important;

}
.date-hidden {
	display: none;
}
.tbed{width: 96px !important;}
.table{width: 100% !important}

.price-container{
	text-align: right;
	.price{border: thin solid $color-primary;  font-size: 1em; border-radius: 42px  42px 0px 42px ; padding: 3px 5px 3px 11px; font-weight: 900; margin-bottom: 10px;
	.bgn{ font-weight: 100; margin-right: -5px;}
}
}


.price-total{font-weight: 900;background: #0275d8; color: white; padding: 8px; text-align: right;}




tr.selected{background-color: #0275d8; color: white;}

.nav-tabs {border-bottom: thick solid #0275d8; border-radius: 0px; height: 40px; margin-top: 0px !important;
	padding: 0px;
	.nav-link:hover{border: none;}

}
.nav-tabs .active{background: #0275d8 !important; color: white !important; border: #0275d8; padding-bottom: 16px;}

//.reservation-room{border-left: thick solid $color-primary}
.reservation-room-header{background: $color-primary; color: white;}
.reservation-traveler{margin-bottom: 3px; border-bottom: thin dotted $color-primary}
//div.active{border-left: thick solid #0275d8 !important; }
/* TODO TEST */
//.carousel-item{overflow: hidden !important}

.passport{background: #fafafa}

.container-footer{
	h5{color: #a9d7ff; font-weight: 900; text-transform: uppercase; text-align: right}

	a{color: white; text-decoration: underline}
	li{list-style: none;}
	.phones{float: right;}
	.col-md-4{border-right: thin solid white; margin: 0px; padding: 0px}
	.footer-brands{background-color: white; padding: 10px;}

	text-align: right;
	background: $color-primary; color:  white; border-top: thick solid $color-primary;
}

/* Underline Reveal */
.hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}
.hvr-underline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-primary;
  height: 8px;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-reveal:hover:before, .hvr-underline-reveal:focus:before, .hvr-underline-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@import 'mobile-app';
